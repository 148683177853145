import { createContext, useReducer } from "react";

export const WarRoomContext = createContext();

export const warRoomActions = {
  TOGGLE_STATE_DETAILS_MODAL: "TOGGLE_STATE_DETAILS_MODAL",
};

const initState = {
  stateDetailsModal: {
    stateName : "",
    open: false
  },
  darkMode: false,
};

const warRoomReducer = (state, action) => {
  switch (action.type) {
    case warRoomActions.TOGGLE_STATE_DETAILS_MODAL:
      return {
        ...state,
        stateDetailsModal: {
          open : !state.stateDetailsModal.open,
          stateName: action.stateName ?? ""
        } ,
      };

    default:
      return state;
  }
};


export const WarRoomContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(warRoomReducer, initState);
  return (
    <WarRoomContext.Provider value={{...state, dispatch}}>
      {children}
    </WarRoomContext.Provider>
  );
};
