const palette = {
  primary: {
    main: "#0050C8",
    dark: "#041E44",
    light: "#F5F8FF", 
    contrastText: "#F8F7FA"
  },
 
  text: {
    main: "#393A4A",   
    light: "#F9FAFB",
    dark: "#DBDFE4",
  },
  secondary: {
    light: "#F2F2F2",
    main: "#6B6C7E",
    dark: "#20242B",
    contrastText: "#f5f7fa"
  },
  common: {
    black: "#262D38",
  },
  grey: {
    100: "#262D38",
    200: "#393F49",
    300: "#605E5C",
    400: "#2A313C",
    800: "#20242B"
  },
  success : {
    main: "#5ACA75"
  },
  tonalOffset: {
    light: "#EDEFF2"
  }
  
};

export default palette;
