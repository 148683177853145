export const authPaths = {
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
};

export const dashboardPaths = {
  DASHBOARD: "/dashboard",
  SECURITY_REPORT: "/dashboard/:state/security-report",
};

export const incidencePaths = {
  INCIDENCE: "/incidence",
  INCIDENCE_DETAILS: "/incidence/:id",
};

export const intelligencePaths = {
  INTELLIGENCE: "/intelligence",
  INTELLIGENCE_DETAILS: "/intelligence/:id",
};

export const reportPaths = {
  REPORT: "/report",
};
export const warRoomPaths = {
  WAR_ROOM: "/war-room",
};
