import { lazy } from "react";
import MainLayout from "layouts/MainLayout";
import WarRoomLayout from "layouts/WarRoomLayout";

export const routes = [
  {
    path: "/*",
    exact: true,
    component: lazy(() => import("modules/Auth")),
  },
  {
    path: "/dashboard/*",
    component: lazy(() => import("modules/Dashboard")),
    layout: MainLayout,
    isAuthenticated: true,
  },
  {
    path: "/incidence/*",
    component: lazy(() => import("modules/Incidence")),
    layout: MainLayout,
    isAuthenticated: true,
  },
  {
    path: "/intelligence/*",
    component: lazy(() => import("modules/Intelligence")),
    layout: MainLayout,
    isAuthenticated: true,
  },

  {
    path: "/security-report/*",
    component: lazy(() => import("modules/SecurityReport")),
    layout: MainLayout,
    isAuthenticated: true,
  },
  {
    path: "/war-room",
    exact: true,
    component: lazy(() => import("modules/WarRoom")),
    layout: WarRoomLayout,
    isAuthenticated: true,
  },
];
