import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Logo } from "assets/svg/app_logo.svg";

/**
 * @module
 * LoadFallback renders a fallback UI used for Suspense
 * This component also accepts a delay prop that can be used to set how many seconds
 * to delay showing the spinner
 */
const DelayedFallback = (props) => {
  const classes = useStyles();
  const { delay } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), delay);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    isVisible && (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        className={classes.spinnerContainer}
      >
        <Logo className="logo" />
      </Box>
    )
  );
};

const useStyles = makeStyles({
  spinnerContainer: {
    "& .logo": {
      animationDuration: "1.5s",
      animationFillMode: "both",
      animationIterationCount: "infinite",
      animationName: "$logo",
      animationTimingFunction: "ease-in-out",
    },
  },
  "@keyframes logo": {
    "0%": {
      opacity: 0.5,
      transform: "scale(0.9)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
});

DelayedFallback.propTypes = {
  delay: PropTypes.number,
};

DelayedFallback.defaultProps = {
  delay: 300,
};

export default React.memo(DelayedFallback);
