import { styled } from "@mui/material";
import PropTypes from "prop-types";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {Outlet} from 'react-router-dom'
import ScrollToTop from "reuseables/ScrollToTop";

const MainLayout = ({ children }) => {
  return (
    <Main>
      <ScrollToTop />
      <Header />
      <aside className="appSideMenu">
        <Sidebar />
      </aside>
      <article className="appContent">{children}<Outlet /></article>
    </Main>
  );
};

const Main = styled("main")(({theme}) => ({
  display: "grid",
  gridTemplateColumns: "230px auto",
  gridTemplateRows: "48px auto",
  gridTemplateAreas: `
    'header header'
    'sidebar content'`,
  /* 
  This line of css causing the scroll functionality to break
  Hopefully, commenting this out doesn't break something else
  */
  // height: "100vh",

  "& .appSideMenu": {
    gridArea: "sidebar",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
  },

  "& .appContent": {
    marginTop: "20px",
    minHeight: "100vh",
    gridArea: "content",
    width: "100%",
    overflowY: "auto",
    backgroundColor: theme.palette.secondary.contrastText
  },
}));

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
