import { WarRoomContextProvider } from 'context/WarRoomContext'

const WarRoomLayout = ({children}) => {
  return (
    <WarRoomContextProvider>
      {children}
    </WarRoomContextProvider>
  )
}

export default WarRoomLayout