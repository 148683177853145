import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from 'react-hot-toast';
import { AppRoutes } from "routes";

const reactQueryConfig = {
  refetchOnWindowFocus: false,
  retry: false,
};

const defaultOptions = {
  queries: reactQueryConfig,
  mutations: reactQueryConfig,
};

const queryClient = new QueryClient({
  defaultOptions,
});

function App() {
  const toastOptions = {
    position: 'top-right',
    duration: 10000,
  };

  return (
    <QueryClientProvider client={queryClient}>
        <AppRoutes />
        <Toaster toastOptions={toastOptions} />
    </QueryClientProvider>
  );
}

export default App;
