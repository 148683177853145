import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import { authPaths } from "./paths";
import Auth from "utils/auth";

export default function PrivateRoutes({ children }) {
  // this would be dynamic when app auth is setUp
  const isLoading = false;
  

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100vh", w: 1 }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!Auth.isAuthenticated()) {
    return <Navigate to={authPaths.SIGNIN} />;
  }

  return children;
}

PrivateRoutes.propTypes = {
  children: PropTypes.node,
};

PrivateRoutes.defaultProps = {
  children: null,
};
