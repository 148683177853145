import { Suspense, Fragment } from "react";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { routes } from "./routes";
import PrivateRoutes from "./PrivateRoutes"
import LoadFallback from "reuseables/LoadFallback";


const renderRoute = ({ component: Component, ...route }) => {
  const Private = route.isAuthenticated ? PrivateRoutes : Fragment;
  const Layout = route.layout ? route.layout : Fragment;

  return (
    <Route
      key={route.path}
      path={route.path}
      element={
        <Layout>
          <Suspense fallback={<LoadFallback/>}>
            <Private>
              <Component />
            </Private>
          </Suspense>
        </Layout>
      }
    />
  );
};

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>{routes.map((route) => renderRoute(route))}</Routes>
    </Router>
  );
};

