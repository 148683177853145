
import { useMemo } from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

import palette  from "./palette";
import typography from "./typography";

const overrides = {
  MuiInputLabel: {
    root: {
      backgroundColor: '#ffffff',
      paddingLeft: '4px',
      paddingRight: '4px'
    }
  }
}

function ThemeConfig({ children }) {
    const themeOptions = useMemo(
      () => ({
        palette,
        typography,
        overrides
      }),
      []
    );
  
    const theme = createTheme(themeOptions);
  
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  export default ThemeConfig
