import { ReactComponent as DashboardIcon } from "assets/svg/dashboardMenuIcon.svg";
import { ReactComponent as IntelligenceIcon } from "assets/svg/intelligenceMenuIcon.svg";
import { ReactComponent as SecurityIcon } from "assets/svg/securityMenuIcon.svg";
import { ReactComponent as IncidenceIcon } from "assets/svg/incidenceMenuIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, MenuItem, MenuList, styled, Typography } from "@mui/material";

const menuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: DashboardIcon,
  },
  {
    title: "Intelligence",
    path: "/intelligence",
    icon: IntelligenceIcon,
  },
  {
    title: "Incidence",
    path: "/incidence",
    icon: IncidenceIcon,
  },
  {
    title: "Security Report",
    path: "/security-report",
    icon: SecurityIcon,
  },
];


const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Box mt="56px">
        {menuItems?.map((tab, tabIndex) => {
          return (
            <MenuList key={tabIndex}>
              <MenuTab
                onClick={() => navigate(tab.path)}
                menustate={tab.path.split("/")[1] === location.pathname.split("/")[1] ? "active" : ""}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  py="14px"
                  px="26px"
                  width="100%"
                >
                  <tab.icon />
                  <Typography ml="18px">{tab.title}</Typography>
                </Box>
              </MenuTab>
            </MenuList>
          );
        })}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  marginTop: 20,
  height: "100vh",
  minWidth: "232px",
  backgroundColor: theme.palette.primary.dark,
  color: "white",
  zIndex: 3
}));

const MenuTab = styled(MenuItem)(({menustate }) => ({
  padding: 0,
  borderLeft: menustate === "active" ? "4px solid white" : "none",
  backgroundColor: menustate === "active" ? "rgba(248, 247, 250, 0.32)" : "transparent",
  height: "40px",

  '& .MuiMenuItem-root:hover': {
    backgroundColor: "rgba(248, 247, 250, 0.32)" 
  }
}));

export default Sidebar;
