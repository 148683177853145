import { Avatar, Box, styled } from "@mui/material";
import { ReactComponent as Logo } from "assets/svg/app_logo.svg";

const Header = () => {
  return (
    <Wrapper>
      <Box>
        <Logo />
      </Box>
      <Box>
        <Avatar sx={{ bgcolor: "#0047BD", width: 32, height: 32, fontSize: "14px", fontWeight: 500 }}>MK</Avatar>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled("div")(() => ({
  position: "fixed",
  backgroundColor: "white",
  zIndex: 2,
  width:"100%",
  padding: "15px 40px 15px 21px",
  boxShadow: "inset 0px -1px 0px #E5E5EA",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}));

export default Header;
